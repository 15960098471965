import {lazy, Suspense, useMemo} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../../_metronic/partials'
import {useAuthRole} from '../../components/hooks/useAuthRole'
import {AuthGuardRoute} from '../../components/utils/AuthGuardRoute'
import CsrPage from '../../modules/default/csr/CsrPage'
import {DashboardWrapper} from '../../pages/dashboard/DashboardWrapper'
import {ContactSupport} from '../../pages/support/ContactSupport'
import {FeatureCode} from '../../utils/RBAC/Feature'

export function PrivateRoutes() {
  const auth = useAuthRole()
  const AcsPage = lazy(() => import('../../modules/default/acs/AcsPage'))
  const SvcPage = lazy(() => import('../../modules/default/svc/SvcPage'))
  const EmsPage = lazy(() => import('../../modules/default/ems/EmsPage'))
  const FnbPage = lazy(() => import('../../modules/default/fnb/FnbPage'))
  const MerchandisePage = lazy(() => import('../../modules/default/merchandise/MerchandisePage'))
  const EvaPage = lazy(() => import('../../modules/default/eva/EvaPage'))
  const CmsPage = lazy(() => import('../../modules/default/cms/CmsPage'))
  const SystemPage = lazy(() => import('../../modules/default/system/SystemPage'))
  const hasDashboard = auth?.canReadFeature('SVCDASHBOARD')
  const hasFnB = auth?.canReadFeature('SVCFNBDASHBOARD')

  const redirectRoutes = useMemo(() => {
    const firstAccessFeature = auth?.getAllApplicationFeature()[0]?.getCode()
    let link = '/error/404'

    if (hasDashboard) {
      link = '/dashboard'
    } else if (firstAccessFeature) {
      const routeMap = ROUTE_MAP[firstAccessFeature]
      if (routeMap) {
        link = routeMap
      }
    }

    return (
      <>
        <Redirect from='/auth' to={link} />
        <Redirect exact from='/' to={link} />
      </>
    )
  }, [auth, hasDashboard])

  if (!auth) {
    return <Redirect to='/auth/login' />
  } else if (auth.isFirstLogin()) {
    return <Redirect to='/auth/password-reset' />
  }

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/acs' component={AcsPage} />
        <Route path='/csr' component={CsrPage} />
        <Route path='/svc' component={SvcPage} />
        {hasFnB && !hasDashboard ? (
          <AuthGuardRoute
            featureCode='SVCFNBDASHBOARD'
            path='/dashboard'
            component={DashboardWrapper}
          />
        ) : (
          <AuthGuardRoute
            featureCode='SVCDASHBOARD'
            path='/dashboard'
            component={DashboardWrapper}
          />
        )}

        <Route path='/ems' component={EmsPage} />
        <Route path='/fnb' component={FnbPage} />
        <Route path='/merchandise' component={MerchandisePage} />
        <Route path='/eva' component={EvaPage} />
        <Route path='/cms' component={CmsPage} />
        <Route path='/support' component={ContactSupport} />
        <Route path='/system' component={SystemPage} />
        {redirectRoutes}
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}

/**
 * Used to redirect user to the first route it has access to on login/page reload.
 * */
const ROUTE_MAP: Record<FeatureCode, string> = {
  ACSGATES: '/acs/gate',
  ACSLOCATION: '/acs/location',
  ACSVENUES: '/acs/venue',
  ACSLOGS: '/acs/log',
  EMSEVENTS: '/ems/event',
  EMSGUESTS: '/ems/event',
  EMSACTIVITY: '/ems/event',
  EMSPRODCAT: '/ems/event',
  EMSPRODUCT: '/ems/event',
  EMSBUNDLE: '/ems/event',
  EMSBOOKINGS: '/ems/event',
  EMSTICKETS: '/ems/event',
  EMSRESERVATIONS: '/ems/event',
  EMSBADGECOLLECTION: '/ems/event',
  EMSDIGITALWAIVER: '/ems/digital-waiver',
  EMSWORKSHOPS: '/ems/event',
  EMSPOKENS: '/ems/event',
  EMSNOTIFICATIONS: '/ems/event',
  EMSBADGE: '/ems/event',
  EMSTEAMTYPES: '/ems/event',
  EMSTEAMS: '/ems/event',
  EMSTEAMMEMBERS: '/ems/event',
  EMSDEVICES: '/ems/event',
  EMSCHANNEL: '/ems/event',

  EVAAGENDA: '/ems/event',
  EVASPEAKERS: '/ems/event',
  EVATOPICS: '/ems/event',
  EVAPOLLS: '/ems/event',

  FNBOUTLETS: '/ems/event',
  FNBPRODCATS: '/ems/event',
  FNBPRODUCTS: '/ems/event',
  FNBUSERS: '/ems/event',
  FNBORDERS: '/ems/event',
  FNBLOGS: '/ems/event',
  FNBTABLERES: '/ems/event',
  FNBQRGEN: '/ems/event',
  FNBIMPORT: '/ems/event',
  FNBINVENTORY: 'ems/event',

  CMSPOSTS: '/cms/post',
  CMSPAGES: '/cms/page',
  CMSFAQS: '/cms/faq',
  CMSFILES: '/cms/file',
  CMSLIBRARY: '/ems/event',
  CMSPARTNER: '/ems/event',

  SVCCUST: '/svc/customer',
  SVCCUSTCATS: '/svc/customer-category',
  SVCDASHBOARD: '/dashboard',
  SVCFNBDASHBOARD: '/dashboard',
  SVCIMPORT: '/svc/import',
  SVCTEMPLATE: '/svc/template',
  SVCWORKFLOW: '/svc/workflow',
  SVCVOUCHER: '/svc/voucher',
  EMSDW: '/ems/digital-waiver',
  VOUCHERCASHIN: '/svc/cash-voucher',
  VOUCHERCASHOUT: '/svc/cash-voucher',
  SVCWORKFLOWBUILDERLOGS: '/svc/workflow',
  SVCWORKFLOWBUILDER: '/svc/workflow',

  MERCHANDISEOUTLETS: '/ems/event',
  MERCHANDISEPRODCATS: '/ems/event',
  MERCHANDISEPRODUCTS: '/ems/event',
  MERCHANDISEORDERS: '/ems/event',
  MERCHANDISEUSERS: '/ems/event',
  MERCHANDISELOGS: '/ems/event',
}
