import {ChangeEvent, LegacyRef, useCallback, useMemo, useRef, useState} from 'react'
import AvatarEditor from 'react-avatar-editor'
import {v4 as uuidv4} from 'uuid'
import {AVATAR_HEIGHT, AVATAR_WIDTH} from '../../../../config/env'
import {CustomerTakePhotoModal} from '../../../modules/default/svc/components/CustomerTakePhotoModal'
import {useEventHandler} from '../../hooks/useEventHandler'
import {useResizeObserver} from '../../hooks/useResizeObserver'
import {SweetAlert} from '../../modals/SweetAlert'
import {useModalState} from '../../modals/useModalState'
import {Button} from '../Button'
import {DragDropFileInputDropArea} from '../FileInput/DragDropFIleInput/DragDropFileInputDropArea'
import {FileInputValueCollection} from '../FileInput/FileInputValueCollection'
import {ImageInputValue} from '../FileInput/ImageInputValue'
import {InputLabel} from '../InputLabel'
import {NumberInput} from '../NumberInput/NumberInput'

export interface AvatarImageInputProps {
  value: ImageInputValue | null
  onChange: (value: ImageInputValue | null) => void
  label?: string
  className?: string
  height?: number
  width?: number
  inputRef?: LegacyRef<HTMLInputElement>
  onDimensionChange?: (height: number, width: number) => void
  disabled?: boolean
}

export const AvatarImageInput = ({
  value,
  onChange,
  label,
  className,
  height = AVATAR_HEIGHT,
  width = AVATAR_WIDTH,
  inputRef,
  onDimensionChange,
  disabled,
}: AvatarImageInputProps) => {
  const id = useRef(uuidv4())
  const [editorRef, setEditorRef] = useState<AvatarEditor | null>(null)
  const [scale, setScale] = useState(1)
  const [image, setImage] = useState<ImageInputValue | null>(null)
  const [avatarContainerEl, setAvatarContainerEl] = useState<HTMLDivElement | null>(null)
  const [avatarContainerSize, setAvatarContainerSize] = useState<{
    height: number
    width: number
  } | null>(null)
  const [avatarCanvasSize, setAvatarCanvasSize] = useState<{height: number; width: number} | null>(
    null
  )
  const {isOpen: isEditModalOpen, open: openEditModal, hide: hideEditModal} = useModalState()
  const {
    isOpen: isTakePhotoModalOpen,
    open: openTakePhotoModal,
    hide: hideTakePhotoModal,
  } = useModalState()
  const avatarCanvas = useMemo(() => {
    return avatarContainerEl?.querySelector<HTMLCanvasElement>('canvas') || null
  }, [avatarContainerEl])

  const fileFactory = useCallback((file: File) => {
    return new ImageInputValue(file)
  }, [])

  useResizeObserver(avatarCanvas, (observerEntry) => {
    setAvatarCanvasSize({
      width: observerEntry.contentRect.width,
      height: observerEntry.contentRect.height,
    })
  })

  useResizeObserver(avatarContainerEl, (observerEntry) => {
    setAvatarContainerSize({
      width: observerEntry.contentRect.width,
      height: observerEntry.contentRect.height,
    })
  })

  const handleDropChange = useCallback(
    (value: FileInputValueCollection<ImageInputValue>) => {
      const image = value.getFirst()
      if (image) {
        setImage(image)
        openEditModal()
      } else {
        onChange(null)
      }
    },
    [openEditModal, onChange]
  )

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = Object.values(e.target.files)[0]
        if (file) {
          setImage(new ImageInputValue(file))
          openEditModal()
        }
      }
    },
    [openEditModal]
  )

  const inputValue = useMemo(() => {
    const inputValue = new FileInputValueCollection<ImageInputValue>()
    if (value) {
      inputValue.add(value)
    }
    return inputValue
  }, [value])

  const imageUrl = useMemo(() => {
    return image?.url
  }, [image])

  const handleScaleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setScale(Number(e.target.value))
  }, [])

  const handlePhotoTaken = useCallback(
    (photo: ImageInputValue) => {
      onChange(photo)
      hideTakePhotoModal()
    },
    [hideTakePhotoModal, onChange]
  )

  const handleSubmit = useCallback(() => {
    if (editorRef) {
      editorRef.getImage().toBlob((blob) => {
        if (blob) {
          const image = new ImageInputValue(blob, 'avatar.png')
          onChange(image)
          hideEditModal()
        }
      })
    }
  }, [editorRef, onChange, hideEditModal])

  const handleHeightChange = useEventHandler((value: number) => {
    onDimensionChange?.(value, width)
  })

  const handleWidthChange = useEventHandler((value: number) => {
    onDimensionChange?.(height, value)
  })

  const avatarEditorScale = useMemo(() => {
    if (avatarCanvas && avatarCanvasSize && avatarContainerSize) {
      const avatarEditorHeight = avatarCanvasSize.height
      const avatarEditorWidth = avatarCanvasSize.width
      const containerHeight = avatarContainerSize.height
      const containerWidth = avatarContainerSize.width
      return Math.min(containerHeight / avatarEditorHeight, containerWidth / avatarEditorWidth)
    }
    return 1
  }, [avatarCanvas, avatarCanvasSize, avatarContainerSize])

  return (
    <div className={className}>
      {label && <InputLabel>{label}</InputLabel>}
      <DragDropFileInputDropArea
        id={id.current}
        fileFactory={fileFactory}
        placeholderText='Drag and drop or click to upload'
        onChange={handleDropChange}
        value={inputValue}
      />
      <input
        accept='image/*'
        onChange={handleInputChange}
        className='d-none'
        type='file'
        id={id.current}
        ref={inputRef}
        disabled={disabled}
      />
      <Button
        variant='primary'
        size='sm'
        className='float-end mt-3'
        onClick={openTakePhotoModal}
        type='button'
      >
        Take photo
      </Button>
      <CustomerTakePhotoModal
        isOpen={isTakePhotoModalOpen}
        onClose={hideTakePhotoModal}
        onSubmit={handlePhotoTaken}
      />
      <SweetAlert open={isEditModalOpen} onClose={hideEditModal} showConfirmButton={false}>
        <div className='swal2-corners'></div>
        {imageUrl && (
          <div className='position-relative p-10'>
            <div
              style={{
                height: '400px',
                width: '100%',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              ref={setAvatarContainerEl}
            >
              <AvatarEditor
                ref={setEditorRef}
                image={imageUrl}
                width={width}
                height={height}
                color={EDITOR_COLOR}
                scale={scale}
                rotate={0}
                style={{transform: `scale(${avatarEditorScale})`}}
              />
            </div>
            <>
              {onDimensionChange && (
                <div className='d-flex'>
                  <NumberInput
                    className='me-1'
                    label='Height'
                    min={1}
                    value={height}
                    step={10}
                    max={1000}
                    onChange={handleHeightChange}
                  />
                  <NumberInput
                    className='ms-1'
                    label='Width'
                    min={1}
                    max={1000}
                    value={width}
                    step={10}
                    onChange={handleWidthChange}
                  />
                </div>
              )}
            </>
            <label className='form-label'>
              Scale
              <input
                type='range'
                min={1}
                value={scale}
                step={0.01}
                max={10}
                className='form-range'
                onChange={handleScaleChange}
              />
            </label>
          </div>
        )}
        <Button className='position-relative' onClick={handleSubmit}>
          Submit
        </Button>
      </SweetAlert>
    </div>
  )
}

const EDITOR_COLOR = [128, 128, 128, 0.2]
