import {useCallback, useState} from 'react'
import {WidgetModel} from '../../../../models/ems/WidgetModel'
import {GetWidgets} from '../redux/EmsCRUD'
import {
  CancelledPromiseError,
  usePromiseManager,
} from '../../../../components/hooks/usePromiseManager'

export interface UseEventDashboardWidgetEmsOptions {
  dailyWidgets?: (keyof WidgetModel)[]
  monthlyWidgets?: (keyof WidgetModel)[]
  date: Date | null
  eventCode?: string
  month?: Date | null
}

export const useEventDashboardEmsWidget = ({
  dailyWidgets,
  monthlyWidgets,
  date,
  eventCode,
  month,
}: UseEventDashboardWidgetEmsOptions) => {
  const [widgetData, setWidgetData] = useState<WidgetModel>({})
  const {isLoading: isDashboardLoading, managePromise} = usePromiseManager()


  const resetWidgetData = useCallback(async () => {
    if (eventCode && date) {
      try {
        const {data} = await managePromise(
          'dashboard',
          GetWidgets({
            widgetCodes: [...(dailyWidgets || []), ...(monthlyWidgets || [])],
            eventCode,
            date: date.toISOString(),
            month: month ? month.getMonth() + 1 : undefined,
            year: month?.getFullYear(),
          })
        )
        // console.log('dataaaa', data);
        setWidgetData(data)
      } catch (e) {
        if (e instanceof CancelledPromiseError) {
          // Ignore cancelled promise.
        }
      }
    }
  }, [eventCode, date, managePromise, dailyWidgets, monthlyWidgets, month])

  return {
    data: widgetData,
    isLoading: isDashboardLoading,
    resetWidgetData,
  }
}
