import {useState} from 'react'
import {Button} from '../../inputs/Button'
import {MetronicIconButton} from '../../inputs/MetronicIconButton'
import {ListCountInput, ListCountInputRefValue} from '../../inputs/VoucherInput/ListCountInput'
import {ListCountInputItemValue} from '../../inputs/VoucherInput/ListCountInputItem'
import {SweetAlert} from '../../modals/SweetAlert'
import {HorizontalDivider} from '../../utils/HorizontalDivider'
import {LoadingSpinner} from '../../utils/LoadingSpinner'
import {ProductVoucherModalType} from '../../../models/booking-wizard/BookingWizard'
import {ProductModel} from '../../../models/ems/ProductModel'
import {FilterModel} from '../../../models/FilterModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {VoucherModel} from '../../../models/svc/VoucherModel'
import {selectItemMapper} from '../../../utils/idExtractor'
import clsx from 'clsx'

interface AddProductVoucherModalProps {
  modalType: ProductVoucherModalType
  onModalClose: () => void
  productSearchResults?: GlobalSearchModel<ProductModel>
  refreshProductsList: (filter?: FilterModel) => void
  productsValues: ListCountInputItemValue<ProductModel>[]
  onProductChange: (value: ListCountInputItemValue<ProductModel>[]) => void
  voucherSearchResults?: GlobalSearchModel<VoucherModel>
  vouchersValues: ListCountInputItemValue<VoucherModel>[]
  onVoucherChange: (value: ListCountInputItemValue<VoucherModel>[]) => void
  searchVouchers: (filter?: FilterModel) => void
  onAdd: () => void
  isPortal?: boolean
  canAdd?: boolean
}

export const AddProductVoucherModal = ({
  modalType,
  onModalClose,
  productSearchResults,
  refreshProductsList,
  productsValues,
  onProductChange,
  voucherSearchResults,
  vouchersValues,
  onVoucherChange,
  searchVouchers,
  onAdd,
  isPortal,
  canAdd,
}: AddProductVoucherModalProps) => {
  const [voucherInputRef, setVoucherInputRef] = useState<ListCountInputRefValue | null>(null)
  const [productInputRef, setProductInputRef] = useState<ListCountInputRefValue | null>(null)

  return (
    <SweetAlert
      showConfirmButton={false}
      open={modalType !== undefined}
      onClose={onModalClose}
      containerClassName='overflow-auto'
      customClass={{htmlContainer: 'overflow-visible'}}
    >
      {isPortal && <div className='swal2-corners' style={{width: '450px'}}></div>}
      <div
        className={clsx('h-100 d-flex flex-column', isPortal && 'position-relative p-0')}
        style={{width: isPortal ? '385px' : ''}}
      >
        <div className='d-flex flex-column flex-grow-1 text-start'>
          {modalType === 'product' ? (
            <>
              <div className='position-absolute top-0 end-0'>
                <MetronicIconButton
                  variant='text'
                  size='md'
                  iconType='Navigation'
                  iconName='Close'
                  tooltip='Close Modal'
                  onClick={onModalClose}
                />
              </div>
              <h2 className='text-start mb-5'>Add product</h2>
              <ListCountInput
                selectPlaceholder='Select a product'
                searchResult={productSearchResults}
                label='Product'
                onChange={onProductChange}
                values={productsValues}
                onSearch={refreshProductsList}
                itemMapper={selectItemMapper}
                listRef={setProductInputRef}
                isInitialValue
                isNew
                isShowRemaining={true}
              />
              <div>
                {productsValues.length > 0 && <HorizontalDivider />}
                <Button
                  className='w-auto mt-3'
                  size='sm'
                  variant='primary'
                  onClick={productInputRef?.addEmptyItem}
                  disabled={productInputRef?.hasBlankItem}
                  uppercase={false}
                  tooltip='Add'
                >
                  Add item
                </Button>
              </div>
            </>
          ) : (
            <>
              <h2 className='text-start mb-5'>Add voucher</h2>
              <ListCountInput
                selectPlaceholder='Select a voucher'
                searchResult={voucherSearchResults}
                label='Voucher'
                onChange={onVoucherChange}
                values={vouchersValues}
                onSearch={searchVouchers}
                itemMapper={selectItemMapper}
                listRef={setVoucherInputRef}
                isInitialValue
                isNew
                isShowRemaining={true}
              />
              <div>
                {vouchersValues.length > 0 && <HorizontalDivider />}
                <Button
                  className='w-auto mt-3'
                  size='sm'
                  variant='primary'
                  onClick={voucherInputRef?.addEmptyItem}
                  disabled={voucherInputRef?.hasBlankItem}
                  uppercase={false}
                  tooltip='Add'
                >
                  Add item
                </Button>
              </div>
            </>
          )}
        </div>
        <div className='d-flex gap-5 justify-content-center mt-5'>
          {isPortal ? (
            <Button
              className='btn btn-primary align-items-center position-relative btn-cut-conrner pe-20 w-100'
              onClick={onAdd}
              disabled={isPortal ? canAdd : false}
              style={{marginRight: '10px'}}
            >
              <LoadingSpinner loading={false}>Save</LoadingSpinner>
            </Button>
          ) : (
            <>
              <Button variant='primary' onClick={onAdd}>
                <LoadingSpinner loading={false}>Save</LoadingSpinner>
              </Button>
              <Button className='me-1' onClick={onModalClose}>
                Close
              </Button>
            </>
          )}
        </div>
      </div>
    </SweetAlert>
  )
}
