import {EnhancedStore} from '@reduxjs/toolkit'
import axios, {AxiosRequestConfig} from 'axios'
import {getUrlData, LAYOUT_TYPE, SCANNER_API_URL} from '../../config/env'
import {RootState} from '../redux/RootReducer'
import store from '../redux/Store'

axios.defaults.baseURL = SCANNER_API_URL

export const axiosScanner = axios.create()

// export default function setupScannerAxios(axiosScanner: AxiosStatic, store: EnhancedStore<RootState>) {
//   const {organizationCode} = getUrlData()

//   axiosScanner.interceptors.request.use(
//       (config) => {
//         const _exception = config.url?.split(':')[0]
//         console.log('config', config);
//         const isToken = _exception === 'https' ? false : true
//         config.headers['x-organization'] = organizationCode

//         if (isToken) {
//           // setBaseUrl(config)
//           setAuthorizationHeader(config, store)
//           // setClientHeader(config)
//         }

//         return config
//       },
//       (err) => Promise.reject(err)
//     )
//   }

axiosScanner.interceptors.request.use(
  async (config) => {
    const {organizationCode} = getUrlData()
    config.headers['x-organization'] = organizationCode

    const _exception = config.url?.split(':')[0]
    const isToken = _exception === 'https' ? false : true
    if (isToken) {
      setAuthorizationHeader(config, store)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosScanner

const setAuthorizationHeader = (config: AxiosRequestConfig, store: EnhancedStore<RootState>) => {
  const {auth, outlet, customerPortal, poken, digitalWaiver} = store.getState()

  switch (LAYOUT_TYPE) {
    case 'outlet': {
      if (outlet.auth?.token) {
        config.headers.Authorization = `Bearer ${outlet.auth.token}`
      }
      break
    }
    case 'customer-delegate':
    case 'customer-portal': {
      if (customerPortal.auth?.token) {
        config.headers.Authorization = `Bearer ${customerPortal.auth.token}`
      }
      break
    }
    case 'digital-waiver': {
      if (digitalWaiver.auth?.token) {
        config.headers.Authorization = `Bearer ${digitalWaiver.auth.token}`
      }
      break
    }

    case 'poken': {
      if (poken.auth?.token) {
        config.headers.Authorization = `Bearer ${poken.auth.token}`
      }
      break
    }
    default: {
      if (auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }
    }
  }
}
