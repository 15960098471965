import {ChangeEvent, useCallback, useMemo} from 'react'
import clsx from 'clsx'
import {FilterModel} from '../../../models/FilterModel'
import {GlobalSearchModel} from '../../../models/GlobalSearchModel'
import {SelectInputItem} from '../SelectInput'
// import {KTSVG} from '../../../../_metronic/helpers'
import {FilterSearchableSelectInput} from '../SearchableSelect/FilterSearchableSelectInput'
import {SeatMapValue} from '../SeatMapInput/SeatMapValue'
import {MetronicIconButton} from '../MetronicIconButton'

export interface ReservationInputItemValue<T> {
  data: T | null //product object
  count: number
  minCount?: number
  id: number | string
  type: string
  isNew?: boolean
  isSeated: boolean
  seatMap: SeatMapValue | null
  locationCode: string
  isFromAddProd: boolean
  remainingQTY?: number
  parentId?: string | null
  maxQTY?: number
}

export interface ReservationInputItemProps<T> {
  className?: string
  value: ReservationInputItemValue<T>
  onChange: (value: ReservationInputItemValue<T>) => void
  onSearch: (filter: FilterModel) => void
  searchResult?: GlobalSearchModel<T>
  selectedItems: ReservationInputItemValue<T>[]
  placeholder: string
  label: string
  onRemove: (value: ReservationInputItemValue<T>) => void
  itemMapper: (data: T) => SelectInputItem
  disabled?: boolean
  isMinCount?: boolean
  isShowRemaining?: boolean
}

export const ReservationInputItem = <T,>({
  searchResult,
  onChange,
  onSearch,
  className,
  value,
  placeholder,
  label,
  onRemove,
  selectedItems,
  itemMapper,
  disabled,
  isMinCount,
  isShowRemaining,
}: ReservationInputItemProps<T>) => {
  // const remainingQty: number = useMemo(() => {
  //   let text = 0
  //   if (value?.data) {
  //     const data: any = value.data
  //     Object.keys(value.data).forEach((item: any) => {
  //       if (item === 'remainingQty') {
  //         text = data[item]
  //       }
  //     })
  //   }
  //   return text
  // }, [value.data])

  const remainingQty: number = useMemo(() => {
    let text = 0
    if (value?.data) {
      const data: any = value.data
      Object.keys(value.data).forEach((item: any) => {
        if (item === 'remainingQty') {
          if (value.remainingQTY === 0) {
            return text
          } else {
            text = data[item]
          }
        }
      })
    }

    return text
  }, [value.data, value.remainingQTY])

  const handleCountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const count = e.target.value
      const newValue: ReservationInputItemValue<T> = {...value, count: Number(count)}
      onChange(newValue)
    },
    [onChange, value]
  )

  const handleSelectionChange = useCallback(
    (item: T | null) => {
      if (item !== null) {
        onChange({...value, data: item})
      }
    },
    [value, onChange]
  )

  const handleRemove = useCallback(() => {
    onRemove(value)
  }, [value, onRemove])

  const filteredSearchResult = useMemo(() => {
    if (searchResult) {
      return {
        ...searchResult,
        data: searchResult.data.filter(
          (item) =>
            !selectedItems.some(
              (selectedItem) =>
                selectedItem.data && itemMapper(item).value === itemMapper(selectedItem.data).value
            )
        ),
      }
    }
  }, [itemMapper, searchResult, selectedItems])

  return (
    <div className={clsx('product-input-item d-flex flex-column gap-2', className)}>
      <label className='form-label mt-5'>{label}</label>
      <div className='product-input-item-input-container'>
        <div className='flex-grow-1' style={{minWidth: 0}}>
          <FilterSearchableSelectInput
            disabled={disabled}
            value={value.data}
            itemMapper={itemMapper}
            searchResult={filteredSearchResult}
            placeholder={placeholder}
            onChange={handleSelectionChange}
            onFilter={onSearch}
            noMargin
          />
        </div>
        <MetronicIconButton
          type='button'
          className='btn btn-icon btn-active-color-danger'
          iconType='General'
          iconName='Trash'
          variant='text'
          size='sm'
          onClick={handleRemove}
          tooltip='Delete'
        />
      </div>

      <div className='d-flex gap-5 justify-content-between'>
        <input
          onChange={handleCountChange}
          className='product-input-item-input-container__number-input form-control form-control-solid'
          type='number'
          disabled={disabled || !value.data}
          value={value.count}
          min={0}
          max={remainingQty > 0 ? remainingQty : undefined}
        />
        {isShowRemaining && remainingQty ? (
          <div className='d-flex flex-column justify-content-center fs-7'>
            {/* {`Remaining Quantity: ${remainingQty - (value.count || 0)}`} */}
            {`Remaining Quantity: ${value.remainingQTY}`}
          </div>
        ) : (
          <div className='d-flex flex-column justify-content-center fs-7'>
            {`Remaining Quantity: 0`}
          </div>
        )}
        {/* {isShowRemaining && remainingQty > 0 ? (
          <div className='d-flex flex-column justify-content-center fs-7'>
            {`Remaining Quantity: ${remainingQty - (value.count || 0)}`}
          </div>
        ) : null} */}
        {isMinCount && (
          <input
            onChange={handleCountChange}
            className='product-input-item-input-container__number-input form-control form-control-solid'
            type='number'
            disabled={disabled || !value.data || value.count === value.minCount}
            value={value.minCount}
            min={0}
          />
        )}
      </div>
    </div>
  )
}
