import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useDebounce} from '../../../components/hooks/useDebounce'
import {useEntityFilter} from '../../../components/hooks/useEntityFilter'
import {useRootStateSelector} from '../../../components/hooks/useRootStateSelector'
import {CustomerModel} from '../../../models/CustomerModel'
import {EventModel} from '../../../models/ems/EventModel'
import {FilterModel} from '../../../models/FilterModel'
import {TicketTable} from '../components/tables/TicketTable/TicketTable'
// import {TicketCardGrid} from '../components/TicketCard/TicketCardGrid'
import {actions} from '../redux/CustomerPortalRedux'
interface TicketsPageProps {
  event?: EventModel
  customer?: CustomerModel
  bookingProductCode?: string
  isDetail?: boolean
}

//CURRENT TICKET PORTAL TABLE
export const TicketsPage = ({event, customer, bookingProductCode, isDetail}: TicketsPageProps) => {
  const tickets = useRootStateSelector((state) => state.customerPortal.tickets)
  const searchDebounce = useDebounce(500)
  const {filters, setFilter} = useEntityFilter('customer-portal-ticket')
  const dispatch = useDispatch()
  const onRefresh = useCallback(() => {
    searchDebounce(() => {
      dispatch(actions.tickets.search())
    })
  }, [dispatch, searchDebounce])

  const initialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['active'],
        type: ['e-ticket'],
        eventCode: event?.code,
      },
    }

    setFilter(filters)
    return filters
  }, [event?.code, setFilter])
  const custInitialFilters = useMemo(() => {
    const filters: FilterModel = {
      filters: {
        status: ['active'],
        type: ['e-ticket'],
        eventCode: event?.code,
        customer: customer?.code,
        bookingProductCode: bookingProductCode,
      },
    }

    setFilter(filters)
    return filters
  }, [bookingProductCode, customer?.code, event?.code, setFilter])

  const onFilterTicketHandler = useCallback(
    (filter: FilterModel) => {
      setFilter(filter)
      onRefresh()
    },
    [setFilter, onRefresh]
  )

  const tableFilters = useMemo(() => {
    if (filters) {
      return filters
    } else {
      const filters: FilterModel = customer ? custInitialFilters : initialFilters
      return filters
    }
  }, [custInitialFilters, customer, filters, initialFilters])

  return (
    <>
      <TicketTable
        initialFilters={customer ? custInitialFilters : initialFilters}
        data={tickets}
        onFilter={onFilterTicketHandler}
        onRefresh={onRefresh}
        event={event}
        filters={tableFilters}
        customer={customer}
        className={isDetail ? '' : 'rs-table-bg'}
        customWrapper='rs-top'
        cornersClassName='table-cut-corner-polygon rs-gradient'
        paginationClassName='px-10 pb-10 rs-pagination'
        removeColumns={removeColumns}
      />
    </>
  )
}

export const removeColumns = ['code', 'productType', 'startedAt', 'endedAt']
