import {useCallback, useMemo, useState} from 'react'
import {FilterInputContainer} from '../../../../../components/tables/advanced-filter-inputs/FilterInputContainer'
import {PortalSearchModel} from '../../../../../models/customer-portal/PortalSearchModel'
import {TicketGroupModel} from '../../../../../models/customer-portal/TicketModel'
import {TicketPortalModel} from '../../../../../models/ems/TicketModel'
import {FilterModel} from '../../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../../models/GlobalSearchModel'
import {EventModel} from '../../../../../models/ems/EventModel'
import {TimeSlotFormatter} from '../../../../default/ems/components/tables/column-formatters/TimeSlotFormatter'
import {PortalTicketProductType} from '../PortalTicketProductType'
import {ColumnStyle} from '../../../../../components/tables/constants/ColumnStyle'
import {TableColumnOptions} from '../../../../../components/tables/TableColumn'
// import {PortalShareStatusColumn} from '../PortalShareStatusColumn'
import {TicketCodeColumnForBooking} from '../TicketCodeColumnForBooking'
import {TicketLocationCodeColumn} from '../TicketLocationCodeColumn'
import {FromProp} from '../BookingProductTable/CustomerProductBookingDetailTable'
import {CustomerModel} from '../../../../../models/CustomerModel'
import {SelectInput, SelectInputItem} from '../../../../../components/inputs/SelectInput'
import {ControlledFilterTable} from '../../../../../components/tables/ControlledFilterTable/ControlledFilterTable'
import {TicketTableSelectionActions} from './TicketTableSelectionActions'
import {useTableOptions} from '../../../../../components/tables/useTableOptions'
import {TableRowId} from '../../../../../components/tables/TableRow'
import {ticketIdExtractor} from '../../../../default/ems/components/utils/ticketUtils'
import {TicketTableRowActions} from './TicketTableRowActions'
import {TicketSeatModalForm, TicketSeatModalFormValues} from '../../modals/TicketSeatModalForm'
import {useModalState} from '../../../../../components/modals/useModalState'
import {useTicketTableActions} from '../../../hooks/useTicketTableActions'
import {FilterType} from '../../../../../components/tables/constants/FilterType'
import {MultiSelectToggleFilterInput} from '../../../../../components/tables/advanced-filter-inputs/MultiSelectToggleFilterInput'
import {TicketStatusColumn} from './TicketTableStatusColumn'
import {TicketDisplayStatusColumn} from '../../../../default/ems/components/tables/column-formatters/TicketDisplayStatusColumn'
import {useOnChange} from '../../../../../components/hooks/useOnChange'
import { WhatsAppInitialValuesProps, WhatsAppModal } from '../../modals/WhatsAppModal'

//CURRENT TICKET PORTAL TABLE
export interface TicketTableProps {
  data?: GlobalSearchModel<TicketPortalModel> | PortalSearchModel<TicketGroupModel>
  onFilter: (filter: FilterModel) => void
  onRefresh: () => void
  onRefreshCallback?: () => void
  event?: EventModel
  initialFilters?: FilterModel
  bookingProductCode?: string
  toPath?: FromProp
  customer?: CustomerModel
  filters: FilterModel
  cornersClassName?: string
  paginationClassName?: string
  customWrapper?: string
  className?: string
  removeColumns?: string[]
}

export const TicketTable = ({
  data,
  onFilter,
  onRefresh,
  onRefreshCallback,
  event,
  initialFilters,
  bookingProductCode,
  toPath = 'tickets',
  customer,
  filters,
  cornersClassName,
  paginationClassName,
  customWrapper,
  className,
  removeColumns,
}: TicketTableProps) => {
  const [expandedGroups, setExpandedGroups] = useState<TableRowId[]>([])
  const [selected, setSelected] = useState<TableRowId[]>([])
  const {
    isOpen: isSelectionModalOpen,
    hide: hideSelectionModal,
    open: openSelectionModal,
  } = useModalState()
  const [activeTicket, setActiveTicket] = useState<TicketPortalModel | null>(null)
  const [selectedTickets, setSelectedTickets] = useState<TicketPortalModel[]>()
  const [openWhatsAppModel, setOpenWhatsAppModel] = useState(false)
  const {setHiddenColumns, hiddenColumns} = useTableOptions({
    tableName: 'portal-ticket',
    defaults: {
      hiddenColumns: [
        'parent',
        'reason',
        'qty',
        'isTimeslot',
        'isSeated',
        'isConsumable',
        'reservationNo',
        'locationName',
        'status',
      ],
    },
  })

  const columns = useMemo(() => {
    const columns: TableColumnOptions<TicketPortalModel>[] = [
      {
        field: 'code',
        label: 'Code',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
        dataExtract: (data) => data.code,
      },
      {
        field: 'bookingCode',
        label: 'Booking',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
        render: ({data}) => {
          if (!bookingProductCode) return TicketCodeColumnForBooking({data, event, customer})
          return <p> {data.bookingCode} </p>
        },
      },
      {
        field: 'customerName',
        label: 'Customer',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
        dataExtract: (data) => data.customerName,
      },
      {
        field: 'customerEmail',
        label: 'Email',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.CODE,
        dataExtract: (data) => data.customerEmail,
      },
      {
        field: 'locationName',
        label: 'Location',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
        render: ({data}) => TicketLocationCodeColumn({data, event, toPath, customer}),
      },

      {
        field: 'seatRow',
        label: 'Row',
        hideable: true,
        sortable: true,
      },
      {
        field: 'seatNo',
        label: 'Seat',
        hideable: true,
        sortable: true,
      },

      {
        field: 'locationSlug',
        label: 'Section',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
        dataExtract: (data) => data.locationSlug,
      },
      {
        field: 'productName',
        label: 'Product',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
        dataExtract: (data) => data.productName,
      },
      {
        field: 'productType',
        label: 'Type',
        sortable: true,
        hideable: true,
        render: ({data}) => <PortalTicketProductType data={data} />,
      },
      {
        field: 'startedAt',
        label: 'Start Date',
        sortable: true,
        hideable: true,
        render: ({data}) => (
          <TimeSlotFormatter className='text-uppercase text-nowrap' isTimeslot={data.isTimeslot}>
            {data.startedAt}
          </TimeSlotFormatter>
        ),
      },
      {
        field: 'endedAt',
        label: 'End Date',
        sortable: true,
        hideable: true,
        render: ({data}) => (
          <TimeSlotFormatter className='text-uppercase text-nowrap' isTimeslot={data.isTimeslot}>
            {data.endedAt}
          </TimeSlotFormatter>
        ),
      },
      {
        field: 'status',
        label: 'Status',
        sortable: true,
        hideable: true,
        // render: ({data}) => <PortalShareStatusColumn data={data} />,
        render: ({data}) => TicketStatusColumn({data}),
      },
      {
        field: 'displayStatus',
        label: 'Portal Status',
        sortable: true,
        hideable: true,
        render: ({data}) => (
          <TicketDisplayStatusColumn data={{displayStatus: data.displayStatus}} uppercase />
        ),
      },
    ]

    if (customer) {
      columns.splice(3, 0, {
        field: 'eventName',
        label: 'Event',
        sortable: true,
        hideable: true,
        cellStyle: ColumnStyle.NAME,
        dataExtract: (data) => data.eventName,
      })
    }
    // return columns
    return columns.filter((column) => !removeColumns?.includes(column.field))
  }, [bookingProductCode, event, toPath, customer, removeColumns])

  const handleGroupByFilter = useCallback(
    (e) => {
      onFilter({...filters, groupBy: e.target.value})
    },
    [filters, onFilter]
  )

  const tableSelectionItems = useMemo(() => {
    if (data) {
      if (Array.isArray(data.data)) {
        return data.data
      }
      return Object.values(data.data).reduce<TicketPortalModel[]>((acc, group) => {
        acc.push(...group)
        return acc
      }, [])
    }
    return []
  }, [data])

  const {isKeyLoading, handleCancelBulk, cancelSingle, reassignSeat,downloadSingle,handleDownloadBulk, handleShareByWhatsAppBulk, isLoading} =
    useTicketTableActions({onRefresh, onRefreshCallback})

  const handleReassignSeat = useCallback(
    async (data: TicketPortalModel) => {
      setActiveTicket(data)
      openSelectionModal()
    },
    [openSelectionModal]
  )

  const handleSelectionModalClose = useCallback(() => {
    hideSelectionModal()
    setActiveTicket(null)
  }, [hideSelectionModal])

  const handleTicketReselectionModalFormSubmit = useCallback(
    async (values: Required<TicketSeatModalFormValues>) => {
      await reassignSeat(values)
      hideSelectionModal()
    },
    [hideSelectionModal, reassignSeat]
  )


  
  const handleOnShareWhatsApp = useCallback((data: TicketPortalModel | TicketPortalModel[]) => {
    if (!Array.isArray(data)) setSelectedTickets([data])
    else setSelectedTickets(data)
    setOpenWhatsAppModel(true)
  }, [])

  useOnChange(openWhatsAppModel, () => {
    if (!openWhatsAppModel) setSelectedTickets(undefined)
  })

  const handleWhatsApp = useCallback(
    async (values: WhatsAppInitialValuesProps) => {
      if (values) {
        if (selectedTickets) {
          const response = await handleShareByWhatsAppBulk(values, selectedTickets)
          if (response) return response
        }
      }
      onRefresh && onRefresh()
    },
    [onRefresh, selectedTickets, handleShareByWhatsAppBulk]
  )

  const rowActions = useCallback(
    (data: TicketPortalModel) => {
      return (
        <TicketTableRowActions
          loading={isKeyLoading(data.code)}
          data={data}
          onReassignSeat={handleReassignSeat}
          onCancel={cancelSingle}
          onShareByWahtsApp={handleOnShareWhatsApp}
          onTicketDownload={downloadSingle}
        />
      )
    },
    [isKeyLoading, handleReassignSeat, cancelSingle, handleOnShareWhatsApp, downloadSingle]
  )

  return (
    <>
      <ControlledFilterTable<TicketPortalModel>
        onHiddenColumnsChange={setHiddenColumns}
        hiddenColumns={hiddenColumns}
        initialFilters={initialFilters}
        className={className}
        cornersClassName={cornersClassName}
        paginationClassName={paginationClassName}
        customWrapper={customWrapper}
        advancedFilters={
          <FilterInputContainer>
            <SelectInput
              placeholder='Select grouping type'
              items={TICKET_GROUP_ITEMS}
              value={filters?.groupBy || ''}
              onChange={handleGroupByFilter}
            />
            <MultiSelectToggleFilterInput
              field='status'
              items={FilterType.BOOKING_PORTAL_STATUS_CUSTOM}
              label=''
            />
          </FilterInputContainer>
        }
        selectionAction={
          <TicketTableSelectionActions
            items={tableSelectionItems}
            selected={selected}
            onCancel={handleCancelBulk}
            isLoading={isLoading}
            onShareByWhatsApp={handleOnShareWhatsApp}
            onDownload={handleDownloadBulk}
          />
        }
        onFilter={onFilter}
        idExtractor={ticketIdExtractor}
        columns={columns}
        filters={filters}
        searchResults={data}
        actions={rowActions}
        selection={selected}
        onSelectionChange={setSelected}
        expandedGroups={expandedGroups}
        onExpandedGroupsChange={setExpandedGroups}
        filterOnMount
      />

      <TicketSeatModalForm
        ticket={activeTicket}
        onClose={handleSelectionModalClose}
        open={isSelectionModalOpen}
        onSubmit={handleTicketReselectionModalFormSubmit}
      />
        {selectedTickets && (
        <WhatsAppModal
          data={selectedTickets}
          setOpen={setOpenWhatsAppModel}
          open={openWhatsAppModel}
          onSubmit={handleWhatsApp}
          isPortal
        />
      )}
    </>
  )
}

const TICKET_GROUP_ITEMS: SelectInputItem[] = [
  {
    label: 'Customer',
    value: 'customerName',
  },
  {
    label: 'Product Name',
    value: 'productName',
  },
  {
    label: 'Ticket',
    value: 'batchId',
  },
]
